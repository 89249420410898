import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import HappySpinner from "../components/HappySpinner";
import { GlobalStyle, globalColors } from "../assets/GlobalStyles";

const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <StyledTrishy>
          <StyledLink to="/T">
            <StyledLetter>T</StyledLetter>
          </StyledLink>
          <StyledLink to="/R">
            <StyledLetter>R</StyledLetter>
          </StyledLink>
          <StyledLink to="/I">
            <StyledLetter>I</StyledLetter>
          </StyledLink>
          <StyledLink to="/S">
            <StyledLetter>S</StyledLetter>
          </StyledLink>
          <StyledLink to="/H">
            <StyledLetter>H</StyledLetter>
          </StyledLink>
        </StyledTrishy>
        <StyledSpinnerWrapper>
          <HappySpinner location="/about" />
        </StyledSpinnerWrapper>
      </Wrapper>
    </>
  );
};

export default IndexPage;

const Wrapper = styled.div`
  width: 100%;
  margin: auto auto;
  @media (max-width: 768px) {
    margin-top: 5em;
  }
`;


const StyledTrishy = styled.div`
  font-family: program-narrow, sans-serif;
  font-weight: 900;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  /* height: 100vh; */
  margin: 20vh auto auto auto;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto auto;
  }
`;
// font-size: ${({ isOpen }) => (isOpen ? "column" : "none")};
const StyledLetter = styled.h1`
  font-size: 20rem;
  margin: 0.02em;
  font-family: program, sans-serif;

font-weight: 900;

font-style: normal;

  @media (max-width: 768px) {
    font-size: 8em;
  }
`;

const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: ${globalColors.nameColor};
  transition-duration: 0.3s;

  &:hover {
    color: ${globalColors.brandWhite};
  }
`;

const StyledSpinnerWrapper = styled.div`
  margin: 0 auto;
  width: 15em;
`;
